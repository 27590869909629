import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import loading from '../../images/spin.gif'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar,faClose,faCalendarDays,faAddressCard,faPenToSquare, faBatteryEmpty} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import '../../Form.css'
import Checklogin from './Checklogin';
import { Link, useNavigate } from 'react-router-dom';
const car = <FontAwesomeIcon icon={faCar} style={{color:'blue'}}/>
const deleteicon = <FontAwesomeIcon icon={faClose}  style={{color:'red'}}/>
const deleteicon2 = <FontAwesomeIcon icon={faClose}  style={{color:'#fff',background:'red'}}/>
const calender = <FontAwesomeIcon icon={faCalendarDays}  style={{color:'green'}}/>
const addresscard = <FontAwesomeIcon icon={faAddressCard} style={{color:'purple'}}/>
const editicon = <FontAwesomeIcon icon={faPenToSquare} style={{color:'blue'}}/>
export default function Driverlist() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const SERVER_PATH = process.env.REACT_APP_SERVER_PATH;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  const navigate=useNavigate()
  const API_PATH = `${SERVER_URL}get_drivers.php`;
  const [drivers, setdrivers] = useState()
  const [isload, setisload] = useState(true)
  const [showload, setshowload] = useState(false)
  const [data, setdata2] = useState([])
  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [modalIsOpen2,setModalIsOpen2] = useState(false);
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { branch: branch, user_role: role,status:1,type:'normal' }
    }).then((res) => {
      console.log(res.data)
      
      setdrivers(res.data)
      setdata2(res.data)
      setisload(false)
    })
      .catch((err) => console.log(err))

  }, [])

  function viewRow(){

  }


  let today = new Date();
  let dd = today.getDate();
  
  let mm = today.getMonth()+1; 
  let yyyy = today.getFullYear();
  if(dd<10) 
  {
      dd='0'+dd;
  } 
  
  if(mm<10) 
  {
      mm='0'+mm;
  } 
  today = yyyy+'-'+mm+'-'+dd;
  const [todaydate, settodaydate] = useState(today)

  const columns = [
       
    {
      name: <div className="datahead">Full name</div>,
      selector: "full_name",
      cell: (row) => <div>
          <p style={{textAlign:'left'}}>{row.full_name}</p>
        
      </div>,
        sortable: true,
        width: '150px !important'
   
    },
    {
        name: <div className="datahead">Branch</div>,
        selector: "branch",
        
        sortable: true
      },
      {
        name: <div className="datahead">Mobile</div>,
        selector: "phone",
        sortable: true
      }
      ,
      // {
      //   name: <div className="datahead">Valid Drivers License</div>,
      //   selector: "license_expiry_date",
      //   sortable: true,
      //   width: '90px !important'
      // }
      // ,
      {
        name: <div className="datahead">Date Employed</div>,
        selector: "date_employed",
        sortable: true
      },
      {
        name: <div className="datahead">Visa Application</div>,
        selector: "visa_app_status",
        cell: (row) => <div>
        <p style={{textAlign:'left'}}>{row.visa_app_status}</p>
      
    </div>,
      sortable: true,
      width: '120px !important'
      }
      ,
      {
        name: <div className="datahead">Leave Request</div>,
        selector: "leave_approval",
        cell: (row) => <div>
        <p style={{textAlign:'left'}}>{row.leave_approval}</p>
      
    </div>,
      sortable: true,
      width: '100px !important'
      }
      ,
      {
        name: <div className="datahead">Driver Status</div>,
        selector: "job_sts",
        cell: (row) => <div>
          <p style={{textAlign:'left',color:'green',fontWeight:'bold'}}>{row.job_sts}</p>
          {row.assignment_sts==''?null:(
 <p style={{textAlign:'left',color:'red'}}>{row.assignment_sts}</p>
          )}
         
      </div>,
        sortable: true,
        width: '80px !important'
      }
      ,
      {
        name:"Action",
        cell: (row) => <><div className="btn-group" role="group" aria-label="Basic example">
          
        <a title="Delete Driver" id={row.id} onClick={()=>deleteAddress(row.id)} className="">{deleteicon}</a>&nbsp; &nbsp;<a  title="Edit Driver" id={row.id} onClick={()=>editDriver(row.id)} className="">{editicon}</a>&nbsp;&nbsp;<br/>
       <a title="Apply Leave" id={row.id} onClick={()=>setModalIsOpenToTrue(row.id)} className="">{calender}</a>&nbsp; &nbsp;<a  title="Apply Visa" id={row.id} onClick={()=>setModalIsOpenToTrue2(row.id)} className="">{addresscard}</a>&nbsp;&nbsp;
      {row.leave_approval=='Approved'||row.leave_approval=='Pending'?(<a  id={row.id} onClick={()=>deleteLeave(row.id)} className="" title="Remove Leave">{deleteicon2}</a>):null}
       </div></>,
        ignoreRowClick: true,
        allowOverflow: true,
        selector: false,
        width: '80px !important'
      },
      ,
      {
        name:"View",
        cell: (row) => <div className="btn-group" role="group"  aria-label="Basic example">
        <a  id={row.id} onClick={()=>viewVehicles(row.id)}  style={{marginLeft:'20px'}}className="">&nbsp;{car}</a></div>,
        ignoreRowClick: true,
        allowOverflow: true,
        selector: false,
        width: '90px !important'
      },
  
  ];
  const tableData = {
    columns,
    data
  };

 
  function deleteAddress(val){
           
    window.confirm('Are you sure  to delete this item?') ? deletethisaddress(val) : deletethisaddress(0)

}
  function deleteLeave(val){
           
    window.confirm('Are you sure to remove the leave request?') ? deletethisleave(val) : deletethisleave(0)

}


const API_PATH1=`${SERVER_URL}get_driver_details.php`;
const [vacationdata, setvacationdata] = useState({
  leave_from:'',
  leave_to:'',
  notes:'',
  leave_type:'',
  driver_id:'',
  leave_form:''
})
const [joindata, setjoindata] = useState({
  driver_name:'',
  change_date:'',
  date_employed:''
})

const [leaveformview, setleaveformview] = useState()
const [settlementformview, setsettlementformview] = useState()
const [msg, setmsg] = useState()
function setModalIsOpenToTrue(val){

  axios({
    method:'post',
     url:`${API_PATH1}`,
     data:val
  }).then((res)=>{
   
   if(res.data.vacationdetails!=null){
    setvacationdata(res.data.vacationdetails)
    if(res.data.vacationdetails.leave_form!=='' && !!res.data.vacationdetails.leave_form){
        const leaveformpath=res.data.vacationdetails.leave_form.split('uploads/')
        
        setleaveformview(`${SERVER_PATH}/uploads/${leaveformpath[1]}`)
      }

      if(res.data.vacationdetails.settlement_form!=='' && !!res.data.vacationdetails.settlement_form){
        const settlement_formpath=res.data.vacationdetails.settlement_form.split('uploads/')
        
        setsettlementformview(`${SERVER_PATH}/uploads/${settlement_formpath[1]}`)
      }
   
   }else{
    setvacationdata({...vacationdata, leave_from:'',
    leave_to:'',
    notes:'',
    leave_type:'',
    driver_id:'',
    leave_form:''
  })

   }
   if(res.data.joindetails!=null){
    setjoindata(res.data.joindetails)
   }
   setmsg('')
   setModalIsOpen(true)
    
  }).catch(err=>console.log(err.message))
}

function setModalIsOpenToFalse(){
  setModalIsOpen(false)
}

const [visadata, setvisadata] = useState({
  driver_name:'',
  visa_type:'',
  date_employed:'',
  branch:'',
  passport_number:'',
  phone:'',
  notes:''
})
const [driver, setdriver] = useState()
const API_VISA=`${SERVER_URL}get_driver_visa_details.php`
function setModalIsOpenToTrue2(val){
  setdriver(val)
  axios({
    method:'post',
     url:`${API_VISA}`,
     data:val
  }).then((res)=>{
    setmsg('')
   setvisadata(res.data.visadetails)
   setModalIsOpen2(true)
    
  }).catch(err=>console.log(err.message))
}

console.log(visadata)
function setModalIsOpenToFalse2(){
  setModalIsOpen2(false)

}

function deletethisaddress(val){
    if(val>0){
       
   const API_PATH=`${SERVER_URL}user_actions.php`;
    axios({
        method: 'post',
        url: `${API_PATH}`,
        data: {user:user,record:val,type:'delete'}
    })
    .then((res)=>{
        console.log(res.data)
        // navigate('/my-account/address-book')
       window.location.reload();

    })
    .catch(err=>console.log(err))
   
}
}
function deletethisleave(val){
    if(val>0){
       
   const API_PATH=`${SERVER_URL}user_actions.php`;
    axios({
        method: 'post',
        url: `${API_PATH}`,
        data: {user:user,record:val,type:'delete_leave'}
    })
    .then((res)=>{
        console.log(res.data)
        // navigate('/my-account/address-book')
       window.location.reload();

    })
    .catch(err=>console.log(err))
   
}
}

const [image_info, setimage_info] = useState({
  filedetails:{
   settlement:'',
   leave_form:''
  }
}) 

function updatefiles(val, i,k) {
 
  const clonedListing = { ...image_info.filedetails };

if(k=='settlement'){
setimage_info({
  filedetails: {
    ...clonedListing,
    settlement: val
  }
});
}else if(k=='leave_form'){
setimage_info({
  filedetails: {
    ...clonedListing,
    leave_form: val
  }
});
}
}

const [msgcolor, setmsgcolor] = useState('green')
const API_UpdateLeave=`${SERVER_URL}user_actions.php`
 function apply_leave(event){
  event.preventDefault();
if(vacationdata && (vacationdata.leave_form || image_info.filedetails.leave_form)){

  setshowload(true)
  axios({
    method:'post',
    url:API_UpdateLeave,
    data:{user:user,vacationdata:vacationdata,joindata:joindata,type:'updateleave'}
  }).then((res)=>{
    console.log(res.data)
    if(res.data>1){ 
      saveimages(event, res.data)
    }
  }).catch((err)=>console.log(err.message))
}else{
  setmsgcolor('red')
  setmsg("Please enter all the required fields")
}
 }
 function saveimages(event, vacationid) {
  console.log(image_info.filedetails);
      event.preventDefault();
      const data = new FormData();
      data.append("leave_form[]", image_info.filedetails.leave_form);
      data.append("vacationid", vacationid)
      data.append("passportno",joindata.passport_number)
      data.append("leaveform2",vacationdata.leave_form)
  
      let url = `${SERVER_URL}vacation_fileupload.php`;
  
      axios.post(url, data, {
        // receive two parameter endpoint url ,form data
      })
        .then((res) => {
          console.log('heree',res.data)
          setshowload(false)
          setmsgcolor('green')
          setmsg(res.data.msg)
          
          setTimeout(()=> {
            setModalIsOpen(false)
            navigate('/drivers'); 
        }, 2000); 
         // navigate('/drivers')
          // then print response status
        
        }, error => {
          alert(error);
        
  
        });
  
  
    }

    const Apply_Visa=`${SERVER_URL}user_actions.php`
    function apply_visa(event){
      event.preventDefault();
     
     if(visadata.applied_for!='' && visadata.applied_for!=null){
      setshowload(true)
      axios({
        method:'post',
        url:`${Apply_Visa}`,
        data:{user:user,visadata:visadata,driver:driver,type:'apply_visa'}
      }).then((res)=>{
        setmsg(res.data.msg)
        setshowload(false)
      }).catch(err=>console.log(err.message))

     }else{
      setmsg('Please enter all the required field')
     }
    }

  function viewVehicles(val){
     navigate(`/view-vehicles/${val}`)
    }
  function editDriver(val){
     navigate(`/edit-driver/${val}`)
    }
  return (
    
    <div className='wrapper-holder driver'>
    
            
      {<SidebarMenu />}

  
  
      <div className="main-panel ps-container">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
             
              <Modal isOpen={modalIsOpen} ariaHideApp={false}>
   
    <>
    
   
          <div className="modal-header">
            <h4 className="modal-title">Apply Vacation / Leave </h4>
            <button type="button" onClick={setModalIsOpenToFalse}  className="close" data-dismiss="modal">×</button>
          </div>
          {/* Modal body */}
          {/* <div className="modal-body"> */}
            <div className="container-fluid modalcontainer">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <form id="myform" encType="multipart/form-data">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop:'0px'}}>
                            <label>Driver</label>
                              <input type="text" id="driver" name="driver" defaultValue={joindata.driver_name}  className="form-control" disabled />
                              <input type="hidden" id="driver_id" name="driver_id" className="form-control" disabled defaultValue />
                            </div>
                          </div>
                          <div className="col-md-6">
                          <label>Leave Type</label>
                            <div className="form-group">
                              <select id="leave_type" name="leave_type" onChange={(e)=>setvacationdata({...vacationdata,leave_type:e.target.value})} defaultValue={vacationdata.leave_type && vacationdata.leave_type!=''?vacationdata.leave_type:''} className="form-control">
                                <option>Select Leave Type</option>
                                <option value="Sick Leave">Sick Leave</option>
                                <option value="Emergency Leave">Emergency Leave</option>
                                <option value="Annual Leave">Annual Leave</option>
                                <option value="Local Leave">Local Leave</option>
                                <option value="Maternity Leave">Maternity Leave</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop: '13px'}}>
                              <label>Leave From <span style={{color: 'red'}}> * </span></label>
                              <input type="date" className="start form-control" onChange={(e)=>setvacationdata({...vacationdata,leave_from:e.target.value})} defaultValue={vacationdata.leave_from} id="start" name="start" />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop: '13px'}}>
                              <label>Leave To <span style={{color: 'red'}}> * </span></label>
                              <input type="date" className="end form-control" id="end" onChange={(e)=>setvacationdata({...vacationdata,leave_to:e.target.value})} defaultValue={vacationdata.leave_to} name="end"  />
                            </div>
                          </div>
                        </div> 
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop: '13px'}}>
                              <label>Joined Date</label>
                              <input type="date" className="start form-control" id="joined_date" defaultValue={joindata.date_employed} name="joined_date" disabled />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group" style={{marginTop: '13px'}}>
                              <label>Change Status Date </label>
                              <input type="date" className="end form-control" id="change_date" defaultValue={joindata.change_date} name="change_date"  disabled />
                            </div>
                          </div>
                        </div> 
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label>Special Notes</label>
                              <div className="form-group">
                                <label className="bmd-label-floating"> </label>
                                <textarea className="form-control" rows={3} onChange={(e)=>setvacationdata({...vacationdata,notes:e.target.value})} name="notes" defaultValue={vacationdata.notes} id="notes" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="w-100">Leave Form <span style={{color: 'red'}}> * </span>
                              <div className="custom-file">
                              <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'leave_form')} />
                           
                              </div>
                            </label>
                            <input type="hidden" name="leave_form1" id="leave_form1" />  
                            <div className="view_form">			   
                            </div>
                            {
                          vacationdata.leave_form && vacationdata.leave_form!=''?(
                             <a href={leaveformview} className="viewform" target="_blank">View Form</a>
                    
                          ):null
                        }		   
                          </div>
                          <div className="col-md-6">
                                  <label className="w-100">Settlement Form 
                                    <div className="custom-file">
                                      <input type="file" style={{ float: 'right' }} onChange={(e) => updatefiles(e.target.files[0], 1, 'settlement')}  disabled/>

                                    </div>
                                  </label>
                                  <input type="hidden" name="leave_form1" id="leave_form1" />
                                  <div className="view_form">
                                  </div>
                                  {
                                vacationdata.settlement_form && vacationdata.settlement_form != '' ? (
                                  <a href={settlementformview} target="_blank" style={{color:'#7f3e98 !important',fontWeight:'bold'}}>View Form</a>

                                ) : null
                              }
                                </div>
                        </div>
                       
                        <p id="sign" />
                        <a className="btn btn-primary pull-right" onClick={e=>apply_leave(e)} style={{color: '#ffffff !important'}}>Apply Leave</a>
                        <div className="clearfix" />
                        {showload?(
                          <img src={loading} style={{width:'5%',marginLeft:'11%'}}/>
                        ):null}
                        <p style={{color:msgcolor}}>{msg}</p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
          {/* Modal footer */}
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary closebtn" onClick={setModalIsOpenToFalse} >Close</button>
        
      </div>
      </>
                  </Modal>

                  {/* visa application  */}

                  <Modal isOpen={modalIsOpen2} ariaHideApp={false}>
   
    <>
    
   
          <div className="modal-header">
            <h4 className="modal-title">Apply For Time Express Visa </h4>
            <button type="button" onClick={setModalIsOpenToFalse2}  className="close" data-dismiss="modal">×</button>
          </div>
          {/* Modal body */}
          {/* <div className="modal-body"> */}
            <div className="container-fluid modalcontainer">
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                    <form id="myform2" encType="multipart/form-data">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className htmlFor="driver">Driver</label>
              <input type="text" id="drivervisa" name="drivervisa" defaultValue={visadata.driver_name} className="form-control" disabled />
              <input type="hidden" id="driver_idvisa" name="driver_idvisa" className="form-control" defaultValue />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group" >
              <label className="bmd-label-static" htmlFor="driver">Visa Application<span style={{color: 'red'}}> * </span></label>
              <select id="visa_apply_type" name="visa_apply_type" defaultValue={visadata.applied_for} onChange={e=>setvisadata({...visadata,applied_for:e.target.value})} className="form-control">
                <option value>Select Visa Application</option>
                <option value="Time Express Visa">Time Express Visa</option>
                <option value="Time Express Visa Renewal">Time Express Visa Renewal</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group" style={{marginTop: '13px'}}>
              <label>Joined Date</label>
              <input type="date" className="start form-control" defaultValue={visadata.date_employed} id="joined_date2" name="joined_date2" disabled />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group" style={{marginTop: '13px'}}>
              <label>Branch</label>
              <input type="text" className="start form-control" id="branch" defaultValue={visadata.branch} name="branch" disabled />
            </div>
          </div>
        </div> 
        <div className="row">
          <div className="col-md-6">
            <div className="form-group" style={{marginTop: '13px'}}>
              <label>Current Visa Type</label>
              <input type="text" className="start form-control" id="visa_type" defaultValue={visadata.visa_type} name="visa_type" disabled />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group" style={{marginTop: '13px'}}>
              <label>Passport Number</label>
              <input type="text" className="start form-control" id="passport_no" defaultValue={visadata.passport_number} name="passport_no" disabled />
            </div>
          </div> 
          <div className="col-md-6">
            <div className="form-group" style={{marginTop: '13px'}}>
              <label>Phone</label>
              <input type="text" className="start form-control" id="phone" defaultValue={visadata.phone} name="phone" disabled />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group" style={{marginTop: '13px'}}>
              <label>Special notes</label>
              <textarea rows={2} className="start form-control" id="visanote" defaultValue={visadata.notes} onChange={e=>setvisadata({...visadata,notes:e.target.value})} name="visanote"  />
            </div>
          </div>
        </div> 
        <div className="row">
        </div>
        <p id="signvisa" />
        <a className="btn btn-primary pull-right" onClick={e=>apply_visa(e)} style={{color: '#fff !important'}} id="addusr">Apply Visa</a>
        <div className="clearfix" />
        {showload?(
                          <img src={loading} style={{width:'5%',marginLeft:'11%'}}/>
                        ):null}
                        <p>{msg}</p>
       </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
          {/* Modal footer */}
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary closebtn" onClick={setModalIsOpenToFalse2} >Close</button>
        
      </div>
      </>
                  </Modal>

 
                <div className="card">
                  <div className="card-header card-header-primary">
                  <h4 className="card-title ">Drivers <span style={{float: 'right'}}> <Link to="/add-driver" title="Add driver">+</Link></span></h4>
                    <p className="card-category"></p>
              
      
                  </div>
                  <div className="card-body">
                  {isload?(
  <div style={{textAlign:"center"}}>
  <p style={{textAlign:"center"}}>Please wait. The data is loading ! </p>

  </div>
):(<DataTableExtensions {...tableData}>
  <DataTable
    columns={columns}
    data={data}
    noHeader
    defaultSortField="id"
    // sortIcon={<SortIcon />}
    defaultSortAsc={true}
    pagination
    highlightOnHover
    dense
  />
</DataTableExtensions>
)}

              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
