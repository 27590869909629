import React from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

export default function General() {
    
    const location = useLocation();  
    const navigate=useNavigate()
    const url=window.location.href
    const segment = url.split("/").pop();

    React.useEffect(() => {
   
        // window.location.replace(`${location.state.payment_page}`)
        if(segment=='km-report2'){
            navigate('/km-report')
        }
        if(segment=='fuel-report2'){
            navigate('/fuel-report')
        }
        if(segment=='fuel-overused2'){
            navigate('/fuel-overused')
        }
      
        if(segment=='fuel-driver2'){
            navigate('/fuel-driver')
        }
      
      }, [])

  return (
    <div>
    
     
    </div>
  )
}
