import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);




export default function Analytics() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  const [driverfuel, setdriverfuel] = useState()

  const [branches, setbranches] = useState()
  const API_PATH1 = `${SERVER_URL}branch.php`;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH1}`,
      data: { branch: branch, user_role: role }
    }).then((res) => {

      setbranches(res.data)
    })
      .catch((err) => console.log(err))

  }, [])

  let today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }
  today = yyyy + '-' + mm + '-' + dd;
  const [todaydate, settodaydate] = useState(today)

  const years = Array.from(new Array(3), (val, index) => index + yyyy);
  const [thisyear, setthisyear] = useState(yyyy)

  // Driver VS Fuel

  const [driver, setdrivercount] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [vehicle, setvehiclecount] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [searchdata, setsearchdata] = useState({
    year: yyyy,
    branch: 0
  })
  const API_PATH = `${SERVER_URL}analytics.php`;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { type: 'default', searchdata: searchdata }
    })
      .then((res) => {

        let driver = res.data.drivers.split('*');
        let vehicles = res.data.vehicles.split('*');

        setdrivercount(driver)
        setvehiclecount(vehicles)

      }).catch(error => console.log(error.message));
  }, [])


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Driver vs Vehicles',
      },
    },
  };


  const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];


  const data = {
    labels,
    datasets: [
      {
        label: "Drivers",
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [driver[0], driver[1], driver[2], driver[3], driver[4], driver[5], driver[6], driver[7], driver[8], driver[9], driver[10], driver[11]]

      },
      {
        label: "Vehicles",
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "rgba(53, 162, 235, 0.5)",
        hoverBorderColor: "rgba(53, 162, 235, 0.5)",
        data: [vehicle[0], vehicle[1], vehicle[2], vehicle[3], vehicle[4], vehicle[5], vehicle[6], vehicle[7], vehicle[8], vehicle[9], vehicle[10], vehicle[11]]

      },
    ],
  };

  function filterdrivervehicle(val, type) {
    if (type == 'branch') {
      setsearchdata({ ...searchdata, branch: val })
    }
    if (type == 'year') {
      setsearchdata({ ...searchdata, year: val })
    }
  }

  useEffect(() => {

    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { type: 'driver_vehicle', searchdata: searchdata }
    })
      .then((res) => {

        let driver = res.data.drivers.split('*');
        let vehicles = res.data.vehicles.split('*');

        setdrivercount(driver)
        setvehiclecount(vehicles)

      }).catch(error => console.log(error.message));
  }, [searchdata])

  // Driver fuel end----------------------------------------------------------------------------------------

  // Leave Applications

  const [searchleave, setsearchleave] = useState({
    year: yyyy,
    branch: 0
  })

  const [driver_leave, setdriver_leave] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [staff_leave, setstaff_leave] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const API_PATH2 = `${SERVER_URL}analytics.php`;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { type: 'leave_application', searchdata: searchdata }
    })
      .then((res) => {
        console.log(res.data)
        let driver_leave = res.data.driver_leave.split('*');
        let staff_leave = res.data.staff_leave.split('*');

        setdriver_leave(driver_leave)
        setstaff_leave(staff_leave)

      }).catch(error => console.log(error.message));
  }, [])

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Leave Applications',
      },
    },
  };

  const data2 = {
    labels,
    datasets: [
      {
        label: "Driver Leave",
        backgroundColor: '#e3ba16',
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "#e3ba16",
        hoverBorderColor: "#e3ba16",
        data: [driver_leave[0], driver_leave[1], driver_leave[2], driver_leave[3], driver_leave[4], driver_leave[5], driver_leave[6], driver_leave[7], driver_leave[8], driver_leave[9], driver_leave[10], driver_leave[11]]

      },
      {
        label: "Staff Leave",
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: [staff_leave[0], staff_leave[1], staff_leave[2], staff_leave[3], staff_leave[4], staff_leave[5], staff_leave[6], staff_leave[7], staff_leave[8], staff_leave[9], staff_leave[10], staff_leave[11]]

      },
    ],
  };


  function filterleave(val, type) {
    if (type == 'branch') {
      setsearchleave({ ...searchleave, branch: val })
    }
    if (type == 'year') {
      setsearchleave({ ...searchleave, year: val })
    }
  }

  useEffect(() => {

    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { type: 'leave_application', searchdata: searchleave }
    })
      .then((res) => {

        let driver_leave = res.data.driver_leave.split('*');
        let staff_leave = res.data.staff_leave.split('*');

        setdriver_leave(driver_leave)
        setstaff_leave(staff_leave)

      }).catch(error => console.log(error.message));
  }, [searchleave])

  //Leave application end............................................

  //Couryier Statistics

  const [searchcouryier, setsearchcouryier] = useState({
    year: yyyy,
    branch: 0
  })

  const [joiners, setjoiners] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [retirements, setretirements] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { type: 'couryier_stat', searchdata: searchcouryier }
    })
      .then((res) => {
        console.log(res.data)
        let joinercount = res.data.joinees.split('*');
        let retirecount = res.data.courier_resigned.split('*');

        setjoiners(joinercount)
        setretirements(retirecount)

      }).catch(error => console.log(error.message));
  }, [])

  const options3 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Courier Statistics',
      },
    },
  };

  const data3 = {
    labels,
    datasets: [
      {
        label: "No.of Joiners",
        backgroundColor: '#2a87d3',
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "#2a87d3",
        hoverBorderColor: "#2a87d3",
        data: [joiners[0], joiners[1], joiners[2], joiners[3], joiners[4], joiners[5], joiners[6], joiners[7], joiners[8], joiners[9], joiners[10], joiners[11]]
      },
      {
        label: "No.of Retirements",
        backgroundColor: '#dc7c15',
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "#dc7c15",
        hoverBorderColor: "#dc7c15",
        data: [retirements[0], retirements[1], retirements[2], retirements[3], retirements[4], retirements[5], retirements[6], retirements[7], retirements[8], retirements[9], retirements[10], retirements[11]]

      },
    ],
  };


  function filtercouryier(val, type) {
    if (type == 'branch') {
      setsearchcouryier({ ...searchcouryier, branch: val })
    }
    if (type == 'year') {
      setsearchcouryier({ ...searchcouryier, year: val })
    }
  }

  useEffect(() => {

    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { type: 'couryier_stat', searchdata: searchcouryier }
    })
      .then((res) => {
        console.log(res.data)
        let joinercount = res.data.joinees.split('*');
        let retirecount = res.data.courier_resigned.split('*');

        setjoiners(joinercount)
        setretirements(retirecount)

      }).catch(error => console.log(error.message));
  }, [searchcouryier])

  //Couryier Statistics End.....................................

  //Fuel Vs Km

  const [searchfuel, setsearchfuel] = useState({
    year: yyyy,
    branch: 0
  })

  const [fuelcost, setfuel] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const [kmcost, setkm] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { type: 'fuel_km', searchdata: searchfuel }
    })
      .then((res) => {
        console.log(res.data)
        let fuelc = res.data.cost.split('*');
        let kmc = res.data.kms.split('*');

        setfuel(fuelc)
        setkm(kmc)

      }).catch(error => console.log(error.message));
  }, [])

  const options4 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Fuel Vs Km',
      },
    },
  };

  const data4 = {
    labels,
    datasets: [
      {
        label: "Fuel",
        backgroundColor: '#f56954',
        borderColor: "#f56954",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "#f56954c7",
        hoverBorderColor: "#f56954c7",
        data: [fuelcost[0], fuelcost[1], fuelcost[2], fuelcost[3], fuelcost[4], fuelcost[5], fuelcost[6], fuelcost[7], fuelcost[8], fuelcost[9], fuelcost[10], fuelcost[11]]
      },
      {
        label: "KM",
        backgroundColor: '#008000',
        borderColor: "#008000",
        borderWidth: 1,
        //stack: 1,
        hoverBackgroundColor: "#008000",
        hoverBorderColor: "#008000",
        data: [kmcost[0], kmcost[1], kmcost[2], kmcost[3], kmcost[4], kmcost[5], kmcost[6], kmcost[7], kmcost[8], kmcost[9], kmcost[10], kmcost[11]]

      },
    ],
  };


  function filterfuel(val, type) {
    if (type == 'branch') {
      setsearchfuel({ ...searchfuel, branch: val })
    }
    if (type == 'year') {
      setsearchfuel({ ...searchfuel, year: val })
    }
  }

  useEffect(() => {

    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { type: 'fuel_km', searchdata: searchfuel }
    })
      .then((res) => {
        console.log(res.data)
        let fuelc = res.data.cost.split('*');
        let kmc = res.data.kms.split('*');

        setfuel(fuelc)
        setkm(kmc)

      }).catch(error => console.log(error.message));
  }, [searchfuel])
  return (

    <div className='wrapper-holder driver'>


      {<SidebarMenu />}



      <div className="main-panel ps-container fuelreport">
        <div className="content" style={{background:'#eeeeee'}}>
          <div className="container-fluid">

            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <select className="form-control" id="filt_branch" onChange={(e) => filterdrivervehicle(e.target.value, 'branch')} >
                      <option value>Select Branch</option>
                      {
                        branches && branches != '' ? (
                          <>
                            {branches.map((e, i) => (
                              branches && branches != "" ? (
                                <>
                                  <option key={i} value={branches[i].id}>{branches[i].branch}</option>


                                </>
                              ) : null
                            )
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      }
                      <option value="0">All</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <select id="year" name="year" defaultValue={thisyear} onChange={(e) => filterdrivervehicle(e.target.value, 'year')} className="form-control">
                      <option value={thisyear}>{thisyear}</option>
                      <option value={thisyear - 1}>{thisyear - 1}</option>
                      <option value={thisyear - 2}>{thisyear - 2}</option>
                    </select>
                  </div>


                </div>
                <Bar options={options} data={data} />
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <select className="form-control" id="filt_branch" onChange={(e) => filterfuel(e.target.value, 'branch')} >
                      <option value>Select Branch</option>
                      {
                        branches && branches != '' ? (
                          <>
                            {branches.map((e, i) => (
                              branches && branches != "" ? (
                                <>
                                  <option key={i} value={branches[i].id}>{branches[i].branch}</option>


                                </>
                              ) : null
                            )
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      }
                      <option value="0">All</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <select id="year" name="year" defaultValue={thisyear} onChange={(e) => filterfuel(e.target.value, 'year')} className="form-control">
                      <option value={thisyear}>{thisyear}</option>
                      <option value={thisyear - 1}>{thisyear - 1}</option>
                      <option value={thisyear - 2}>{thisyear - 2}</option>
                    </select>
                  </div>


                </div>
                <Line options={options4} data={data4} />
              </div>

            </div>

            <div className="row" style={{ marginTop: '4%' }}>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <select className="form-control" id="filt_branch" onChange={(e) => filterleave(e.target.value, 'branch')} >
                      <option value>Select Branch</option>
                      {
                        branches && branches != '' ? (
                          <>
                            {branches.map((e, i) => (
                              branches && branches != "" ? (
                                <>
                                  <option key={i} value={branches[i].id}>{branches[i].branch}</option>


                                </>
                              ) : null
                            )
                            )}
                          </>
                        ) : (
                          <></>
                        )
                      }
                      <option value="0">All</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <select className="form-control" id="filt_year" onChange={(e) => filterleave(e.target.value, 'year')} >
                      <option value={2022}>2022</option>
                      <option value={2021}>2021</option>
                    </select>
                  </div>
                </div>
                <Bar options={options2} data={data2} />
              </div>
              <div className="col-md-6">

                <div className="row">
                  <div className="col-md-6">
                    <select id="year" name="year" defaultValue={thisyear} onChange={(e) => filtercouryier(e.target.value, 'year')} className="form-control">
                      <option value={thisyear}>{thisyear}</option>
                      <option value={thisyear - 1}>{thisyear - 1}</option>
                      <option value={thisyear - 2}>{thisyear - 2}</option>
                    </select>
                  </div>


                </div>
                <Bar options={options3} data={data3} />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
