import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import SidebarMenu from '../SidebarMenu'
import '../../Form.css'
import Checklogin from './Checklogin';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

export default function EditDriver() {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const SERVER_PATH = process.env.REACT_APP_SERVER_PATH;
  const branch = sessionStorage.getItem('branch')
  const role = sessionStorage.getItem('role')
  const user = sessionStorage.getItem('userid')
  const navigate=useNavigate()
  const API_PATH = `${SERVER_URL}branch.php`;
  const [branches, setbranches] = useState()
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_PATH}`,
      data: { branch: branch, user_role: role }
    }).then((res) => {
      console.log(res.data)
      setbranches(res.data)
    })
      .catch((err) => console.log(err))

  }, [])

  const options = branches?.map(data => ({
    label: `${data.branch}`,
    value: `${data.id}`
  }))

 
       
  const [driverdata, setdriverdata] = useState({
    branch:'',
    first_name:'',
    last_name:'',
    common_name:'',
    employee_number:'',
    phone:'',
    date_employed:'',
    citizenship:'',
    passport_number:'',
    pdrp_expiry_date:'',
    license_type:'',
    license_expiry_date:'',
    change_date:'',
    work_permit_number:'',
    work_permit_expiry:'',
    dob:'',
    emirates_id_no:'',
    license_no:'',
    visa_type:'',
    own_vehicle:'',
    home_contact:'',
    home_contact_no:'',
    res_address:'',
    res_city:'',
    ctc:'',
    cpk:'',
    notes:'',
    passport_doc:''

  })

  const url=window.location.href
  const driverid = url.split("/").pop();

  const [existfiles, setexistfiles] = useState({
    photo:'',
    license:'',
    passport:'',
    visa:'',
    emirates_id:'',
    labour_contract:'',
    other_doc:''

  })
 const [displaypic, setdisplaypic] = useState('')
 const [displaylicense, setlicense] = useState('')
 const [displaypassport, setpassport] = useState('')
 const [displayvisa, setvisa] = useState('')
 const [displayeid, seteid] = useState('')
 const [displaylaborcontract, setlaborcontract] = useState('')
 const [displayotherdoc, setotherdoc] = useState('')
  const API_DRIVER = `${SERVER_URL}basic_details.php`;
  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_DRIVER}`,
      data: {driverid:driverid,type:'driver'}
    }).then((res) => {     
      console.log(res.data)
      setdriverdata(res.data)
      if(res.data.photo!=='' && !!res.data.photo){console.log('photo ',res.data.photo)
        const photopath=res.data.photo.split('uploads/')
        
        setdisplaypic(`${SERVER_PATH}/uploads/${photopath[1]}`)
      }
      
      if(res.data.driving_license!='' && !!res.data.driving_license){
        const licensepath=res.data.driving_license.split('uploads/')
       setlicense(`${SERVER_PATH}/uploads/${licensepath[1]}`)
      }
      if(res.data.passport!='' && !!res.data.passport){
        const passportpath=res.data.passport.split('uploads/')
        setpassport(`${SERVER_PATH}/uploads/${passportpath[1]}`)
      }
      if(res.data.visa!='' && !!res.data.visa){
        const visapath=res.data.visa.split('uploads/')
      setvisa(`${SERVER_PATH}/uploads/${visapath[1]}`)
      }
      if(res.data.emirates_id!='' && !!res.data.emirates_id){
        const emirates_idpath=res.data.emirates_id.split('uploads/')
        console.log('eid: ',emirates_idpath)
       seteid(`${SERVER_PATH}/uploads/${emirates_idpath[1]}`)
      }
      if(res.data.labour_contract!='' && !!res.data.labour_contract){
        const labour_contractpath=res.data.labour_contract.split('uploads/')
       setlaborcontract(`${SERVER_PATH}/uploads/${labour_contractpath[1]}`)
      }
      if(res.data.other_doc!='' && !!res.data.other_doc){
        const otherdocpath=res.data.other_doc.split('uploads/')
       setotherdoc(`${SERVER_PATH}/uploads/${otherdocpath[1]}`)
      }
     
      
    })
      .catch((err) => console.log(err))

  }, [])


  // const [selectBranch, setSelectBranch] = useState({ label: "DHL", value: driverdata.branch });

  const [drivervacation, setdrivervacation] = useState()

  useEffect(() => {
    axios({
      method: 'post',
      url: `${API_DRIVER}`,
      data: {driverid:driverid,type:'driver_vacation'}
    }).then((res) => {
     // console.log(res.data)
     setdrivervacation(res.data)
    })
      .catch((err) => console.log(err))

  }, [])
  

  const [msg, setmsg] = useState()
  const [msgcolor, setmsgcolor] = useState()
  const API_PATH2=`${SERVER_URL}update_driver.php`
  function submit_driver(event){
    console.log('driverid ',driverid)
    event.preventDefault();
    if(driverdata.branch !='' && driverdata.first_name!=''){
        axios({
          method:'post',
          url:`${API_PATH2}`,
          data:{'user':user,'driverdata':driverdata,'driverid':driverid}
        }).then((res)=>{
         setmsg(res.data.msg)
         setmsgcolor('green')
         saveimages(event, driverid)
        }).catch((err)=>console.log(err))
    }else{
      setmsg('Please fill all the required fields')
      setmsgcolor('red')
    }
  }


  const [image_info, setimage_info] = useState({
    filedetails:{
     photo:'',
     passport:'',
     visa:'',
     emirates_id:'',
     license:'',
     labor_contract:'',
     other:''
    }
  }) 

  function updatefiles(val, i,k) {
    //const updatedCount = 1;
    const clonedListing = { ...image_info.filedetails };
    //const updatedAccepted = [...clonedListing.image]
   // updatedAccepted[i] = val;
if(k=='photo'){
  setimage_info({
    filedetails: {
      ...clonedListing,
    photo: val
    }
  });
}else if(k=='passport'){
  setimage_info({
    filedetails: {
      ...clonedListing,
    passport: val
    }
  });
}
else if(k=='visa'){
  setimage_info({
    filedetails: {
      ...clonedListing,
    visa: val
    }
  });
}
else if(k=='emirates_id'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      emirates_id: val
    }
  });
}
else if(k=='license'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      license: val
    }
  });
}
else if(k=='labor_contract'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      labor_contract: val
    }
  });
}
else if(k=='other'){
  setimage_info({
    filedetails: {
      ...clonedListing,
      other: val
    }
  });
}
   
   
  }




  function saveimages(event, orderid) {
console.log(image_info.filedetails);
    event.preventDefault();
    const data = new FormData();
    data.append("photo[]", image_info.filedetails.photo);
    data.append("passport[]", image_info.filedetails.passport);
    data.append("visa[]", image_info.filedetails.visa);
    data.append("emirates_id[]", image_info.filedetails.emirates_id);
    data.append("license[]", image_info.filedetails.license);
    data.append("labor_contract[]", image_info.filedetails.labor_contract);
    data.append("other[]", image_info.filedetails.other);
    data.append("orderno", orderid)
    data.append("passportno",driverdata.passport_number)

    data.append("photo1",driverdata.photo)
    data.append("passport1",driverdata.passport)
    data.append("visa1",driverdata.visa)
    data.append("emirates_id1",driverdata.emirates_id)
    data.append("license1",driverdata.driving_license)
    data.append("labour_contract1",driverdata.labour_contract)
    data.append("other_doc1",driverdata.other_doc)
    data.append("uploadtype",'edit')

    let url = `${SERVER_URL}fileupload.php`;

    axios.post(url, data, {
      // receive two parameter endpoint url ,form data
    })
      .then((res) => {
        console.log(res.data)
setmsg('Details Updated Successfully')
setmsgcolor('green')

      // navigate('/drivers')
        // then print response status

      }, error => {
        alert(error);
      

      });


  }

  const [msg2, setmsg2] = useState()
    function closeLeave(val,val2){
   
    
    if(drivervacation.rejoindate !=''){
        axios({
          method:'post',
          url:`${API_DRIVER}`,
          data:{'user':user,'rejoindate':drivervacation.rejoindate,'driverid':val,'vacationid':val2,type:'close_driver_leave'}
        }).then((res)=>{
          if(res.data==1){
            setmsg2('Leave Closed successfully')
          }else{
            setmsg2('Unable to update the details')
          }
         
        
         
        }).catch((err)=>console.log(err))
    }else{
      setmsg('Please fill all the required fields')
      setmsgcolor('red')
    }
  }

  return (
    <div className='wrapper-holder'>
      {<SidebarMenu />}
    
      <div className="main-panel ps-container">
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header card-header-primary">
                  
                    <div className="row">
                      <div className="col-md-8">   <h4 className="card-title">Edit Driver</h4><p className="card-category">Complete your profile </p></div>
                      <div className="col-md-4"> <img src={displaypic} style={{width:'27%',float:'right',maxHeight:'120px'}}/></div>
                    </div>
                   
                  

                  </div>
                  <div className="card-body">
                    <form id="myform" encType="multipart/form-data">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">



                            <label className>Branch <span style={{ color: 'red' }}>*</span></label>
                            {/* <br/><Select
        className="basic-single"
        classNamePrefix="select"
        onChange={(choice) => setdriverdata({...driverdata,branch:choice.value})}
        // onChange={(e)=>setdriverdata({...driverdata,branch:options.value})}
        defaultValue={selectBranch}
        name="color"
        options={options}
      /> */}
                            <select id="branch" name="branch" value={driverdata.branch} onChange={(e)=>setdriverdata({...driverdata,branch:e.target.value})} className="form-control " >
                              <option value>Select Branch</option>
                              {
                                branches && branches != '' ? (
                                  <>
                                    {branches.map((e, i) => (
                                      branches && branches != "" ? (
                                        <>
                                          <option key={i} value={branches[i].id} >{branches[i].branch}</option>


                                        </>
                                      ) : null



                                    )
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )
                              }

                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Fist Name <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="first_name" name="first_name" defaultValue={driverdata.first_name} onChange={(e)=>setdriverdata({...driverdata,first_name:e.target.value})} className="form-control" />
                          </div>
                        </div>

                        {/* <input type="file" />
                        <input type="file" onChange={handleChange2}/> */}
                        
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating" style={{ marginTop: '5px' }}>Last Name <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="last_name" name="last_name" className="form-control" defaultValue={driverdata.last_name} onChange={(e)=>setdriverdata({...driverdata,last_name:e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Common Name</label>
                            <input type="text" id="common_name" name="common_name" defaultValue={driverdata.common_name} className="form-control" onChange={(e)=>setdriverdata({...driverdata,common_name:e.target.value})} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Employee Number</label>
                            <input type="text" id="employee_number" name="employee_number" defaultValue={driverdata.employee_number} className="form-control" onChange={(e)=>setdriverdata({...driverdata,employee_number:e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Mobile Number<span style={{ color: 'red' }}> *</span></label>
                            <input type="text" id="mobile_number" name="mobile_number" defaultValue={driverdata.phone} className="form-control" onChange={(e)=>setdriverdata({...driverdata,phone:e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Date Employed <span style={{ color: 'red' }}>*</span></label>
                            <input type="date" id="emp_date" name="emp_date" className="form-control" defaultValue={driverdata.date_employed} onChange={(e)=>setdriverdata({...driverdata,date_employed:e.target.value})}/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Citizenship <span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="citizenship" name="citizenship" className="form-control" defaultValue={driverdata.citizenship}  onChange={(e)=>setdriverdata({...driverdata,citizenship:e.target.value})}/>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Passport Number<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="passport_number" name="passport_number" onblur="exist_pass()" defaultValue={driverdata.passport_number} className="form-control" onChange={(e)=>setdriverdata({...driverdata,passport_number:e.target.value})}/>
                            <p id="reg_msg" style={{ color: 'red' }} />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Passport Expiry Date</label>
                            <input type="date" id="pdrp_expiry" name="pdrp_expiry" className="form-control" defaultValue={driverdata.pdrp_expiry_date} onChange={(e)=>setdriverdata({...driverdata,pdrp_expiry_date:e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-4" style={{ display: 'none' }}>
                          <div className="form-group">
                            <label className="bmd-label-floating"> Code</label>
                            <input type="text" id="code" name="code" defaultValue={driverdata.employee_number} className="form-control"  />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4" style={{ marginTop: '-5px' }}>
                          <div className="form-group">
                            <label className="bmd-label-floating">Select Licence Type <span style={{ color: 'red' }}>*</span></label>
                            <select id="license_type" name="license_type" value={driverdata.license_type} onChange={(e)=>setdriverdata({...driverdata,license_type:e.target.value})} className="form-control" style={{ marginTop: '-17px' }}>
                              <option value>Select </option>
                              <option value="Manual">Manual</option>
                              <option value="Automatic">Automatic</option>
                              <option value="Heavy">Heavy</option>
                              <option value="Multi">Multi</option>
                              <option value="Motorcycle">Motorcycle</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Driving license Expiry Date <span style={{ color: 'red' }}>*</span></label>
                            <input type="date" id="license_expiry" name="license_expiry" defaultValue={driverdata.license_expiry_date} onChange={(e)=>setdriverdata({...driverdata,license_expiry_date:e.target.value})} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Change status Date</label>
                            <input type="date" id="change_date" name="change_date" defaultValue={driverdata.change_date} onChange={(e)=>setdriverdata({...driverdata,change_date:e.target.value})} className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating">Work Permit Number</label>
                            <input type="text" id="work_permit_number" name="work_permit_number" defaultValue={driverdata.work_permit_number} onChange={(e)=>setdriverdata({...driverdata,work_permit_number:e.target.value})} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Work Permit Expiry Date</label>
                            <input type="date" id="work_permit_expiry" defaultValue={driverdata.work_permit_expiry} name="work_permit_expiry" onChange={(e)=>setdriverdata({...driverdata,work_permit_expiry:e.target.value})} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Date of Birth</label>
                            <input type="date" id="dob" name="dob" className="form-control" defaultValue={driverdata.dob}  onChange={(e)=>setdriverdata({...driverdata,dob:e.target.value})}/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating">Emirates ID Number</label>
                            <input type="text" id="eid_number" name="eid_number" defaultValue={driverdata.emirates_id_no} onChange={(e)=>setdriverdata({...driverdata,emirates_id_no:e.target.value})} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating datefiled">Licence Number<span style={{ color: 'red' }}>*</span></label>
                            <input type="text" id="license_number" name="license_number" defaultValue={driverdata.license_no}  onChange={(e)=>setdriverdata({...driverdata,license_no:e.target.value})} className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating">Visa Type<span style={{ color: 'red' }}>*</span></label>
                            <select id="visa_type" name="visa_type" value={driverdata.visa_type}  onChange={(e)=>setdriverdata({...driverdata,visa_type:e.target.value})} className="form-control">
                              <option value={1}>Employment Visa (Timexpress)</option>
                              <option value={2}>Visit Visa</option>
                              <option value={3}>Freelance Visa</option>
                              <option value={4}>Cancelled</option>
                              <option value={5}>Employment Visa (other)</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating">Own Vehicle?</label>
                            <select id="own_vehicle" name="own_vehicle" value={driverdata.own_vehicle} onChange={(e)=>setdriverdata({...driverdata,own_vehicle:e.target.value})} className="form-control">
                              <option value>select</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Primary contact(Home Country )</label>
                            <div className="form-group">
                              <label className="bmd-label-floating"> </label>
                              <input type="text" id="home_contact" name="home_contact" defaultValue={driverdata.home_contact} onChange={(e)=>setdriverdata({...driverdata,home_contact:e.target.value})} className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Contact Number (Home Country )</label>
                            <div className="form-group">
                              <label className="bmd-label-floating"> </label>
                              <input type="text" id="home_contact_no"  defaultValue={driverdata.home_contact_no} name="home_contact_no" onChange={(e)=>setdriverdata({...driverdata,home_contact_no:e.target.value})} className="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Residential Address</label>
                            <div className="form-group">
                              <label className="bmd-label-floating"> </label>
                              <textarea className="form-control" rows={1} name="res_address" defaultValue={driverdata.res_address} onChange={(e)=>setdriverdata({...driverdata,res_address:e.target.value})}  />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating">Residential City</label>
                            <select id="res_city" name="res_city"  value={driverdata.res_city} onChange={(e)=>setdriverdata({...driverdata,res_city:e.target.value})} className="form-control">
                              <option value>select city</option>
                              <option value="Dubai">Dubai</option>
                              <option value="Abudhabi">Abudhabi</option>
                              <option value="Sharjah">Sharjah</option>
                              <option value="Ajman">Ajman</option>
                              <option value="Ras Al Khaima">Ras Al Khaima</option>
                              <option value="Fujairah">Fujairah</option>
                              <option value="Umm Al Quwain">Umm Al Quwain</option>
                              <option value="Al Ain">Al Ain</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="bmd-label-floating">Salary per Month</label>
                            <input type="text" id="ctc" name="ctc" defaultValue={driverdata.ctc} onChange={(e)=>setdriverdata({...driverdata,ctc:e.target.value})} className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input type="checkbox" id="cpk" onChange={(e)=>setdriverdata({...driverdata,cpk:e.target.value})} name="cpk" defaultValue={1} />
                            <label htmlFor="vehicle1"> Add to Cost Per KM</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Special Notes</label>
                            <div className="form-group">
                              <label className="bmd-label-floating"> </label>
                              <textarea className="form-control" rows={2} name="notes" defaultValue={driverdata.notes} onChange={(e)=>setdriverdata({...driverdata,notes:e.target.value})} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h3 className="documents">Documents  <span style={{ color: '#00000', fontSize: '12px' }}> jpg,png,jpeg,pdf </span></h3>
                      {/*	<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
*/}
                      <style dangerouslySetInnerHTML={{ __html: "\n.custom-file-input:lang(en)~.custom-file-label::after {\n    content: \"Browse\";\n    background: #9c27b0;\n    color: #fff;\n    padding-bottom: 0px;\n    margin-bottom: 0px;\n}\n.custom-file-label::after {\n\t\n\tpadding: 0.7875rem 1rem !important;\n}\n.custom-file{\n\tbackground: #efebeb !important;\n}\n\n" }} />
                      
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Photo<span style={{ color: 'red' }}>*</span></label>
                      
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 0,'photo')}/>
                         
                          
                        </div>
                        <div className="col-md-6">
                        {driverdata.photo && driverdata.photo!=0?(
                            <a href={displaypic} className="viewform" target="_blank" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Passport</label>
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'passport')} />
                         </div>
                         <div className="col-md-6">
                        {driverdata.passport && driverdata.passport!=0?(
                            <a href={displaypassport} target="_blank"  className="viewform" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Visa</label>
                         <input type="file" style={{float:'right'}}onChange={(e) => updatefiles(e.target.files[0], 1,'visa')}/>
                          </div>
                          <div className="col-md-6">
                        {driverdata.visa && driverdata.visa!=0?(
                            <a href={displayvisa} target="_blank"  className="viewform" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Emirates Id</label>
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'emirates_id')} />
                            </div>
                            <div className="col-md-6">
                        {driverdata.emirates_id && driverdata.emirates_id!=0 ?(
                            <a href={displayeid} target="_blank"  className="viewform" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Driving License<span style={{ color: 'red' }}>*</span></label>
                          <input type="file" style={{float:'right'}}   onChange={(e) => updatefiles(e.target.files[0], 1,'license')}/>
                           </div>

                           <div className="col-md-6">
                        {driverdata.driving_license && driverdata.driving_license!=0?(
                            <a href={displaylicense} target="_blank"  className="viewform" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Labor Contract</label>
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'labor_contract')}/>
                            </div>
                            <div className="col-md-6">
                        {driverdata.labour_contract && driverdata.labour_contract!=0?(
                            <a href={displaylaborcontract} className="viewform"  target="_blank" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        <label className="filelabel">Other Doc</label>
                          <input type="file" style={{float:'right'}} onChange={(e) => updatefiles(e.target.files[0], 1,'other')}/>
                           </div>
                           <div className="col-md-6">
                        {driverdata.other_doc && driverdata.other_doc!=0?(
                            <a href={displayotherdoc} target="_blank" className="viewform" style={{marginTop:"5%"}}>View file</a>
                          ):null}
                        </div>
                      </div>
    
                      <p style={{color:msgcolor,fontSize:'16px',marginTop:'2%'}}>{msg}</p>
                      <a className="btn btn-primary pull-right" onClick={e=>submit_driver(e)} style={{ color: '#fff !important' }} id="addusr">Update Profile</a>
                      <div className="clearfix" />
                     
                    </form>
                  </div>

                  {drivervacation && drivervacation.appoval_status==1?(

                    <div className="card-body closeleaveform">
                     <form id="myform2" encType="multipart/form-data">
        <div className="leave_class">
          <h3 className="documents" style={{marginTop: '0px'}}>Leave Information</h3>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Leave Type</label>
                <input type="text" className="end form-control" defaultValue={drivervacation.leave_type} disabled />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group" style={{marginTop: '13px'}}>
                <label>Leave From</label>
                <input type="text" className="end form-control" defaultValue={drivervacation.leave_from} disabled />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group" style={{marginTop: '13px'}}>
                <label>Leave To</label>
                <input type="text" className="end form-control" defaultValue={drivervacation.leave_to} disabled />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group">
                <label>Rejoined Date</label>
                <input type="date" className="end form-control" onChange={(e)=>setdrivervacation({...drivervacation,rejoindate:e.target.value})} id="rejoin" name="end" />
              </div>
            </div>
          </div>
          <p id="sign2" />
          <a className="btn btn-primary pull-right" onClick={(e)=>closeLeave(drivervacation.driver_id,drivervacation.id)} style={{color: '#fff !important', background: '#352c2c'}} id="addusr">Close Leave</a>
          <div className="clearfix" />
         
        </div></form>
        <p>{msg2}</p>
                    </div>
                  ):null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
